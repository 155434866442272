import { CssBaseline, useTheme } from '@mui/material'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import {
  Fbq, Gtag, UserEventsProvider,
} from '@xylabs/react-pixel'
import { assertEx } from '@xylabs/sdk-js'
import { BasePage } from '@xylabs/sdk-react'
import { webThemeOptions } from '@xyo-network/react-sdk'
import deepmerge from 'deepmerge'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import Rollbar from 'rollbar'

import { XnsNameStatus } from './components/index.ts'
import {
  PixelProvider, SettingsLoader, useSettings,
} from './contexts/index.ts'
import { useXyoUserEvents } from './hooks/index.ts'
import { getXnsName, overrideThemeOptions } from './lib/index.ts'
import { partialDarkThemeOptions } from './partialDarkThemeOptions.tsx'
import { partialLightThemeOptions } from './partialLightThemeOptions.tsx'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowAny = window as any

windowAny.rollbar = new Rollbar({
  accessToken: assertEx(import.meta.env.VITE_ROLLBAR_TOKEN, () => 'Rollbar token not set'),
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? 'development',
})

Fbq.init('1653480428329003')
Gtag.init('G-ZSGG0QNJW7', 'AW-989965544', ['xyo.network'])

const AppBody: React.FC = () => {
  const userEvents = useXyoUserEvents()
  const xnsName = getXnsName()

  return (
    <PixelProvider id="xyo.network">
      <UserEventsProvider userEvents={userEvents}>
        <BrowserRouter>
          <BasePage container="lg" title={xnsName ?? 'Unknown xNS Name'}>
            <XnsNameStatus xnsName={xnsName} />
          </BasePage>
        </BrowserRouter>
      </UserEventsProvider>
    </PixelProvider>
  )
}

const AppThemeBody: React.FC = () => {
  const { darkMode } = useSettings()
  const theme = useTheme()
  const overrideOptions = overrideThemeOptions(theme)
  return (
    <InvertibleThemeProvider
      dark={darkMode}
      darkOptions={partialDarkThemeOptions}
      lightOptions={partialLightThemeOptions}
      options={deepmerge(webThemeOptions, overrideOptions)}
    >
      <AppBody />
    </InvertibleThemeProvider>
  )
}

export const App: React.FC = () => {
  return (
    <SettingsLoader>
      <Helmet defaultTitle="XYO" titleTemplate="%s" />
      <CssBaseline />
      <AppThemeBody />
    </SettingsLoader>
  )
}
