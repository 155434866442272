import {
  Paper, Skeleton, Typography,
} from '@mui/material'
import { AxiosJson } from '@xylabs/axios'
import {
  ButtonEx, FlexCol, FlexRow,
  usePromise,
} from '@xylabs/sdk-react'
import type { ModuleQueryResult } from '@xyo-network/module-model'
import type { DNSResponse } from '@xyo-network/xns-record-payload-plugins'
import { isDNSResponse } from '@xyo-network/xns-record-payload-plugins'
import React from 'react'

import { generateXnsQuery } from '../lib/index.ts'

export const XnsNameStatus: React.FC<{ xnsName?: string | null }> = ({ xnsName }) => {
  const [registered] = usePromise(async () => {
    if (xnsName) {
      const queryUrl = generateXnsQuery(xnsName, 'XYOA')
      const axios = new AxiosJson()
      const [, payloads] = (await axios.get<{ data: ModuleQueryResult }>(queryUrl)).data?.data
      console.log('payloads', payloads)
      const dnsPayload = payloads?.find(isDNSResponse) as DNSResponse | undefined
      console.log('dnsPayload', dnsPayload)
      const answer = dnsPayload?.Answer?.shift()
      console.log('answer', answer)
      return !!answer
    }
    return false
  }, [xnsName])

  return (
    <Paper sx={{
      overflow: 'hidden', margin: 2, borderRadius: 4,
    }}
    >
      <FlexCol alignItems="stretch" gap={2}>
        <FlexRow bgcolor="lightslategray" paddingY={1}>
          <Typography variant="h1">{xnsName}</Typography>
        </FlexRow>
        {(registered === undefined)
          ? <Skeleton sx={{ maxWidth: 320, alignSelf: 'center' }} />
          : (registered
              ? <Typography alignSelf="center" variant="body1">This XNS domain is already owned</Typography>
              : <Typography alignSelf="center" variant="body1">This XNS domain might be for sale</Typography>)}
        <FlexRow paddingBottom={1}>
          <ButtonEx
            variant="contained"
            href={
              document.location.hostname.endsWith('beta.xyo.network')
                ? `https://beta.xyo.network/xns/estimation?name=${xnsName}`
                : `https://xyo.network/xns/estimation?name=${xnsName}`
            }
          >
            Find out more
          </ButtonEx>
        </FlexRow>
      </FlexCol>
    </Paper>
  )
}
